import React from "react";
import MainLayout from "../components/layoutmain";
import {graphql, Link} from "gatsby";
import Post from "src/components/post";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import moment from "moment/dist/moment";
import Tags from "../components/posts/tag";
import "moment/dist/locale/fr"


export default function BlogList({data, pageContext}) {
   moment.locale('fr')
   const { currentPage, numPages, topActu } = pageContext
   const topNews = topActu.node.frontmatter.title === "not-showing" ? null : topActu
   let timeReading;
   let thumbnail;
   let title;

   if(topNews){
      timeReading = topNews ? Math.round((topNews.node.html.replace(/(<([^>]+)>)/gi, "").split(" ").length) / 300 - 0.5) : 0
      thumbnail = getImage(topNews.node.frontmatter.thumb)
      title = topNews.node.frontmatter.title
   }

   const seoProps = {
      title: "Numeko - Actualites",
      description:
         "Rejoindre une société pas comme les autres. Nous recherchons tout d'abord des femmes & hommes passionnés et ingénieux.",
   };

   const isFirst = currentPage === 1
   const isLast = currentPage === numPages
   const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
   const nextPage = (currentPage + 1).toString()

   function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
   }

   return (
      <MainLayout seoProps={seoProps}>
         <div className={"actualites "}>
            <div className={"bloc-category bloc-presentation"}>
               <h1>Blog</h1>
               <hr />
               <div className={"top-news"}>
                  {
                     topNews ?
                        <>
                           <div className={"image-container"}>
                              {(topNews.node.frontmatter.thumb)
                                 ? <GatsbyImage image={thumbnail} alt={title} className={"presentation-image-thumbnail"} />
                                 : <StaticImage src={"../images/no_picture.png"} alt={title} className={"presentation-image-thumbnail"} />
                              }
                           </div>
                           <div className={"content-article"}>
                              <div className={"article-header"}>
                                 <div className={"date"}>{moment(topNews.node.frontmatter.creation_date).format("DD MMMM YYYY")}</div>
                                 <Tags tags={topNews.node.frontmatter.author} author />
                              </div>
                              <div className={"article-title"}>
                                 <h2>{capitalizeFirstLetter(topNews.node.frontmatter.title)}</h2>
                              </div>
                              <div className={"article-link"}>
                                 <Link to={`/actualites/${constructPath(topNews.node.frontmatter.title)}`}>Lire l'article</Link>
                              </div>
                              <span className={"reading-time"}>Lecture de {timeReading === 0 ? "1" : timeReading} min</span>
                           </div>
                        </>
                        :
                        <div className={"container-soon"}>
                           <h3>Bientôt disponible</h3>
                           <h4>...Et d'autres contenus en cours de rédaction</h4>
                        </div>
                  }
               </div>
            </div>
            <div className={"bloc-category bloc-content background-waves-one-color-duck "}>
               <div className={"container-blog"} id={"blog-part"}>
                  <div className={"articles-container"}>
                     {
                        data.allMarkdownRemark.edges.length > 0 ?
                           data.allMarkdownRemark.edges.map(post => {

                              if(post.node.frontmatter.title !== "not-showing"){
                                 const { title, creation_date, description, thumb, tags } = post.node.frontmatter;
                                 const html = post.node.html

                                 return (
                                    <Post
                                       id={post.node.id}
                                       title={capitalizeFirstLetter(title)}
                                       date={moment(creation_date).format("DD MMMM YYYY")}
                                       description={description}
                                       path={`/actualites/${constructPath(title)}`}
                                       tags={tags}
                                       thumb={thumb}
                                       html={html}
                                    />
                                 )
                              }else{
                                 return (
                                    ""
                                 )
                              }
                           })
                           :
                           <h1>Aucune actualité revenez plus tard !</h1>
                     }
                  </div>

                  {
                     topNews ?
                        <div className={"pagination-container"}>

                           {!isFirst && (
                              <Link to={`/actualites/${prevPage}`} rel="prev">
                                 {"<"}
                              </Link>
                           )}
                           {Array.from({ length: numPages }, (_, i) => (
                              <Link key={`pagination-number${i + 1}`} to={`/actualites/${i === 0 ? "" : i + 1}`}>
                                 {i + 1}
                              </Link>
                           ))}

                           {!isLast && (
                              <Link to={`/actualites/${nextPage}`} rel="next">
                                 >
                              </Link>
                           )}
                        </div>
                        :
                        ""
                  }

               </div>
            </div>
         </div>
      </MainLayout>
   );
}


export const AllBlogsQuery = graphql`
  query AllBlogPosts($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    sort: {fields: frontmatter___creation_date, order: DESC}
    filter: {frontmatter: {status: {eq: "En ligne"}}}
    limit: $limit
    skip: $skip
    ) {
    edges {
      node {    
        frontmatter {
          creation_date
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
          status
          author {
            first_name
            last_name
          }
        }
        id
        html
      }
    }
  }
}
`

function constructPath (title) {
   return title.toLowerCase().replaceAll(" ", "-")
}
