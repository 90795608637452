import React from "react";
import { Link } from "gatsby";
import {getImage, StaticImage, GatsbyImage} from "gatsby-plugin-image";

const Post = ({ id, title, date, description, tags, path, thumb, html }) => {

    const thumbnail = getImage(thumb)
    const timeReading = Math.round((html.replace(/(<([^>]+)>)/gi, "").split(" ").length) / 300 + 0.2)

    return (

        <div className="post" key={id}>
            <Link to={ path } className={"link"}>
                <div>
                    {(thumb)
                       ? <GatsbyImage image={thumbnail} alt={title} className={"thumbnail"} />
                       : <StaticImage src={"../images/no_picture.png"} alt={title} className={"thumbnail"} />
                    }
                </div>
                <div className={"content"}>
                    <p className={"date"}>{date}</p>
                    <p className={"text"}>{title}</p>
                    <div className={"footer"}>
                        <div className={"article-link"}>Lire l'article</div>
                        <p>Lecture de {timeReading === 0 ? "1" : timeReading} min</p>
                    </div>
                </div>
            </Link>
        </div>

    );
}

export default Post;
